import React, { Component } from 'react'
import './Slider_client.scss'


export class Item extends Component 
{

    constructor(props) 
    {
        super(props)

        this.state = 
        {
            level: this.props.level
        }
    }


    render() 
    {
        const classLevel = 'item level' + this.props.level

        return(
            <div className={classLevel}>
                <div className="blanc"/>
                <h1 className='title_level0'>{this.props.data.title}</h1>
            </div>
        )
    }    
}

export default Item
