import React, { Component } from 'react'
import './Slider_client.scss'


export class Item extends Component 
{

    constructor(props) 
    {
        super(props)

        this.state = 
        {
            level: this.props.level
        }
    }


    render() 
    {
        const imagem = 'img_slider img_tipo' + this.props.level
        const imageUrl = require(`../../assets/${this.props.data.picture}`)

        return(
            <div 
                className={imagem}
                style={{ backgroundImage: `url(${imageUrl})` }}
            />
        )
    }    
}

export default Item
