import React, { Component } from 'react'

import './Slider.scss'


const data =
[
    {
        "pic": require("../../assets/home_header_1.png"),
        "title": "Mentoria Financeira",
        "desc": "UMA MANEIRA DIFERENTE DE TRABALHAR",
        "number": '01'
    },
    {
        "pic": require("../../assets/home_header_3.png"),
        "title": "Estruturação de Negócios",
        "desc": "TUDO O QUE VOCÊ PRECISA SABER SOBRE SUA EMPRESA, ANTES MESMO DELA EXISTIR",
        "number": '02'
    },
    {
        "pic": require("../../assets/home_header_2.png"),
        "title": "Avaliação de Negócios",
        "desc": "VALORES BASE PARA AQUISIÇÕES E FUSÕES",
        "number": '03'
    }
]




export class Slider extends Component 
{
    state =
    {
        data: data
    }


    componentDidMount = () =>
    {
        this.myTimer()
    }

    myTimer = () =>
    {
        this.timer = setInterval(() => 
            {
                this.slideRight()
            }, 
            5000
        )
    }

    handleClick = val =>
    {
        if (val === 'left')
        {
            this.moveLeft()
        }
        else if (val === 'right')
        {
            this.slideRight()
        }

        clearInterval(this.timer)
        this.myTimer()
    }


    moveLeft = () => 
    {
        let last = this.state.data[this.state.data.length - 1]
        let rest = this.state.data.slice(0, -1)
        let data = [last, ...rest]

        this.setState(
            {
                data: data
            }
        )
    }
    
    slideRight = () => 
    {
        let [first, ...rest] = this.state.data
        let data = [...rest, first]

        this.setState(
            {
                data: data
            }
        )
    }


    render() 
    {
        const image = this.state.data[0].pic

        return (
            <div className='slider'>

                <div className='sliderBox'>
                    
                    <img 
                        src={require('../../assets/up_arrow.png')}
                        onClick={ () => this.handleClick('left') }
                        className='up_arrow'
                        alt='up_arrow'
                    />

                    <img 
                        src={require('../../assets/down_arrow.png')}
  
                        onClick={ () => this.handleClick('right') }
                        className='down_arrow'
                        alt='down_arrow'
                    />

                    <div className='tracinho'/>

                    <div className='circulo'/>


                    <h1 className='sliderbox_h1'>{this.state.data[0].number}</h1>

                    <h3 className='h3one'>
                        {this.state.data[1].number}
                    </h3>

                    <h3 className='h3two'>
                        {this.state.data[2].number}
                    </h3>

                </div> 


                <img 
                    src={require('../../assets/logo.svg')} 
                    className='slider_logo'
                    alt='logo' 
                />       

                <h2 className='slider_header_title'>{this.state.data[0].title}</h2>

    
                <h1 className='title'>{this.state.data[0].desc}</h1>


                <div className="wrapper_image_header">
                    <div 
                        style={{ backgroundImage: `url(${image})` }}
                        className='home_header_pic'
                        alt='home_header_pic'
                    />    
                </div>
               
            
            </div>
        )
    }
}

export default Slider
